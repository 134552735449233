.Tabs {
    min-height: 100%;
}

.Tabs ul {
    display: flex;
    align-content: center;
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--bg-color);
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.Tabs li {
    cursor: pointer;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid black;
  border-width: 0px 1px 0 1px;
}

.acitve-tab-window {
    background-color: white;
    height: 100%;
    width: 100%;
}
